import React from 'react';
import styled from 'styled-components';
import SEO from 'src/components/seo';
import Footer from 'src/components/footer';

export default ({ children }) => (
  <Container>
    <SEO />
    {children}
    <Footer />
  </Container>
);

const Container = styled.div`
  flex: 1;
`;
