import { css } from 'styled-components';

export const colors = {
  green100: '#EAFFEE',
  green200: '#8EE8A0',
  green300: '#5BCA49',
  green400: '#009B20',
  green900: '#02240A',
};

export const sizes = {
  phoneOnly: 679,
  tabletPortraitUp: 680,
  tabletLandscapeUp: 900,
  laptopUp: 1000,
};

export const forSize = Object.keys(sizes).reduce((accumulator, sizeLabel, index) => {
  const mediaQueryTemplateFn =
    index > 0
      ? (...args) => css`
          @media (min-width: ${sizes[sizeLabel]}px) {
            ${css(...args)};
          }
        `
      : (...args) => css`
          @media (max-width: ${sizes[sizeLabel]}px) {
            ${css(...args)};
          }
        `;

  accumulator[sizeLabel] = mediaQueryTemplateFn;
  return accumulator;
}, {});
