import React from 'react';
import styled from 'styled-components';
import { colors, forSize } from '../styled-utils';

import logo from '../assets/app-icon.png';
import ChevronRightIcon from '../assets/chevron-right.inline.svg';

export const Logo = () => (
  <img src={logo} style={{ width: '112px', height: '112px' }} alt="GitPigeon Logo" />
);

export const H1 = styled.h1`
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;

  margin: 0;
  color: ${(props) => props.color || '#000000'};

  ${forSize.tabletLandscapeUp`
    line-height: 1.3;
  `}
`;

export const H2 = styled.h2`
  font-size: 28px;
  font-weight: normal;
  line-height: 1.2;

  margin: 0;
  color: ${(props) => props.color || '#000000'};
`;

export const Text = styled.p`
  display: inline-block;
  margin: 8px 0 0 0;

  color: ${(props) => props.color || 'inherit'};

  font-size: ${(props) => (props.big ? '24px' : '20px')};
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  line-height: 1.3;
`;

export const Button = styled.a`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 18px 34px;

  border-radius: 10px;
  border: none;
  text-decoration: none;

  cursor: pointer;
`;

export const PrimaryButton = styled(Button)`
  background-color: #57cb44;
  color: #e5ffec;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), inset 0px 4px 10px rgba(255, 255, 255, 0.1);
  transition: 0.2s ease-in;

  &:hover {
    background-color: #50d03b;
    box-shadow: 0 5px 20px 2px rgb(80, 208, 59, 0.3);
    transform: scale(1.01);
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: rgba(234, 255, 238, 0.6);
  color: #02240a;
  transition: 0.1s ease-in;

  &:hover {
    background-color: ${colors.green100};
  }
`;

export const Pill = styled.a`
  position: relative;
  font-size: 14px;
  font-weight: 500;

  padding: 6px 16px 6px 16px;

  color: ${colors.green900};
  background-color: rgba(0, 113, 15, 0.08);

  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;

  transition: 0.2s ease-in;

  &:hover {
    background-color: rgba(0, 113, 15, 0.15);
  }
`;

export const PillWithIcon = styled(Pill)`
  padding: 6px 32px 6px 16px;
`;

const PillChevronRight = styled(ChevronRightIcon)`
  position: absolute;
  right: 4px;
  top: 0;

  width: 30px;
  height: 30px;
  padding: 10px;

  path {
    fill: ${colors.gree900};
    opacity: 0.6;
  }
`;

export const PillWithChevron = (props) => (
  <PillWithIcon {...props}>
    {props.children} <PillChevronRight />
  </PillWithIcon>
);

export const DownloadButton = () => (
  <PrimaryButton href="https://updates.gitpigeon.com/GitPigeon.latest.dmg">
    Download for macOS
  </PrimaryButton>
);

const SectionWithEmojiHeading = styled(H2)`
  ${forSize.tabletPortraitUp`
    margin-left: ${(props) => '-' + (props.emojiWidth ?? '41px')};
  `}

  ${forSize.phoneOnly`
    text-align: center;
  `}
`;

const Emoji = styled.span`
  ${forSize.phoneOnly`
    display: block;
  `}
`;

export const SectionWithEmoji = ({ emoji, heading, children, emojiWidth, ...props }) => (
  <section {...props}>
    <SectionWithEmojiHeading emojiWidth={emojiWidth}>
      <Emoji>{emoji}&nbsp;</Emoji> {heading}
    </SectionWithEmojiHeading>
    {children}
  </section>
);
