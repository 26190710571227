import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { trackCustomEvent, OutboundLink } from 'gatsby-plugin-google-analytics';

import { forSize, colors } from '../styled-utils';
import { Logo, H2, DownloadButton } from './base';

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 112px 0 0 0;
`;

const Heading = styled(H2)`
  margin: 16px 5% 20px 5%;
  text-align: center;
`;

const FooterList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin: 32px 0 32px 0;
  padding: 0;
  list-style: none;

  ${forSize.tabletLandscapeUp`
    flex-direction: row;
  `}
`;

const FooterListItem = styled.li`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 12px 0;

  color: ${colors.green300};
  text-align: center;

  a {
    font-weight: 600;
    text-decoration: none;

    &:hover {
      color: ${colors.green400};
      transition: 0.2s;
    }
  }

  ${forSize.tabletLandscapeUp`
    margin: 0 8px;
  `}
`;

const FooterListDot = styled(FooterListItem)`
  display: none;

  ${forSize.tabletLandscapeUp`
    display: inline-block;
  `}
`;

function Footer(props) {
  function handleDownloadButtonClick() {
    trackCustomEvent({
      category: 'Button Clicks',
      action: 'Click Download App in Footer',
      label: 'Download App',
    });
  }

  return (
    <FooterWrapper>
      <Logo alt="GitPigeon Logo" style={{ marginLeft: '-24px' }} />
      <Heading>
        Get&nbsp;<span style={{ fontWeight: 500 }}>GitPigeon</span>, it's&nbsp;free!&nbsp;💚
      </Heading>
      <DownloadButton onClick={handleDownloadButtonClick} />
      <FooterList>
        <FooterListItem>
          Brought to you by{' '}
          <OutboundLink href="https://monofocus.app/?utm_source=gitpigeon&utm_medium=landing&utm_campaign=footer">
            MonoFocus
          </OutboundLink>
        </FooterListItem>
        <FooterListDot>•</FooterListDot>
        <FooterListItem>
          <Link to="/privacy">Privacy Policy</Link>
        </FooterListItem>
        <FooterListDot>•</FooterListDot>
        <FooterListItem>
          <OutboundLink href="https://www.dropbox.com/s/g58o9i9paz0mc2e/PressKit.zip?dl=1">
            PressKit
          </OutboundLink>
        </FooterListItem>
        <FooterListDot>•</FooterListDot>
        <FooterListItem>
          <OutboundLink href="mailto:hello@monofocus.app">Contact Us</OutboundLink>
        </FooterListItem>
      </FooterList>
    </FooterWrapper>
  );
}

export default Footer;
