import styled from 'styled-components';
import { forSize } from '../styled-utils';

export default styled.section`
  position: relative;

  width: calc(100% - 64px);
  margin: 0 32px;

  @media (max-width: 380px) {
    width: calc(100% - 32px);
    margin: 0 16px;
  }

  ${forSize.tabletPortraitUp`
    width: 74%;
    margin: 0 13%;
  `}

  ${forSize.laptopUp`
    max-width: 840px;
    margin: 0 auto;
  `}
`;
